export  const sc = {
    login: {
        "App Store 下载":"App Store",
		"Google Play 下载":"Google Play",
        "注册":"立即注册",
        "title": "用Visa/Master信用卡<br/>购买虚拟货币！",
        "li1": "BTCC成立于 2011 年，是全球运营时间最长的虚拟货币交易所",
        "li2": "美国 MSB 及 加拿大 MSB 牌照",
        "li3": "提供18种以上虚拟货币交易",
        "li4": "支持 9 种以上法定货币存款",
        "li5": "提供 1 - 150 的杠杆进行交易",
        "首页url":"https://lp.btcc.academy/zh-CN",
        "首页":"首页",
        "关于我们":"关于 BTCC",
        "about":"zh-CN/about-cn.html",
        "about_in":"about_in-cn.html",
        "about_dm":"about_dm-cn.html",
        "about_coupon":"about_coupon-cn.html",
        "about_rebate":"about_rebate-cn.html",
        "about_lottery":"about_lottery-cn.html",

        "tab1":"邮箱注册",
        "email": "请输入邮箱地址",
        "code": "请输入验证码",
        "Send": "获取验证码",
        "pwd": "请设置密码",
        "tab2": "手机注册",
        "mobile": "请输入手机号码",
        "Register": "立即注册",
        "服务条款":"https://www.btcc.com/zh-CN/detail/134261.html",
        "隐私声明":"https://www.btcc.com/zh-CN/detail/134259.html",
        "风险提示":"https://www.btcc.com/zh-CN/detail/149034.html",



        "mobileTip": "请输入正确的手机号码",
        "emailTip": "请输入正确的邮箱地址",
        "codeTip": "请输入正确的验证码",
        "pwdTip": "至少8位字符，必须包含大小写字母、数字或特殊字符",
        "tips":"我已阅读，并接受",
		"tips1":"《服务条款》",
        "tips2":"《隐私协议》",
		"tips3":"《风险提示》" ,
		"tipslang":"https://www.btcc.com/detail/142641.html",

        registeSuccessInfo: "十分感谢，注册成功。 ",
        startTrading: "立即开始交易！",
        registeSuccessInfoPhone: "十分感谢，注册成功。 ",
        startTradingPhone: "立即开始交易！",
        
    },
    SupportedCurrencies: {
        title: "支持的货币",
        p: "选择你使用的货币来为你的账户存款和注资。",

    },
    LeverageUp: {
        title: "18种主流虚拟货币最高提供150倍杠杆",
        head1: "杠杆",
        head2: "虚拟货币",
        p: "未来将引入更多虚拟货币"

    },
    cost: {
      title: "比全球收费标准更低",
      p: "BTCC的收费其他虚拟货币交易所更低更吸引，将你的每笔交易成本降到最低。",
      h3: "手续费",
      p2: "我们为你的每一笔交易提供低至0.03%的交易费。在你的交易过程中，成本将不是负担！",
      h4: "点差",
      p3: "对下单犹豫不决？我们提供最准确的报价，让你获得最小的买卖差价。",
      p4: "其他交易平台"

    },
    btns: {
        text1: "立即注册",
        text2: "下载BTCC APP"
    },
    step: {
        title: "在 30 秒内开始交易",
        step1: "下载",
        step2: "注册",
        step3: "充值",
        step4: "交易",
        Download:"下载BTCC APP"

    },
    BTCC: {
        title: "客户如何评价 BTCC?",
        p: "深受新手和专业投资者的信赖",
        h4: "汇款、交易方便",
        p2: "用APP就可以轻松汇款和交易, 还可以用信用卡买币。",
        head2: "T0m",
        head3: "适合初学者的APP",
        p3: "BTCC的交易费很低，而且有很多活动, 所以当初下载来试试~他们有初学者指南和很多教学内容，最近我还在尝试模拟交易，累积经验再去实战!",
        head4: "mandichen",
        head5: "用BTCC好幾年了",
        p4: "BTCC運營了10多年，不怕會突然倒閉🤣而且常常有好玩的活動!",
        head6: "479251",

        
        h42:"易用给赞",
		p22:"他们家有模拟交易, 像我一样的小白用户也能轻松学会交易数字货币合约!",
		head22:"小白",
		head32:"不坑钱",
		p32: "之前一次bitcoin急跌，其他家很多都有插针拔网线害我亏了钱，这家没有。",
        head42: "MC tang",
        head52: "更新了有点不习惯",
        p42: "但是内容变得丰富多了，每天登录还有积分可换USDT，像游戏一样好玩",
        head62: "粉粉",

		h43:"交易体验还不错",
		p23:"这个软件还不错，从开户到交易的教学都挺完善的。信用卡买币的手续费也不贵！ ",
		head23:"little silver",
		head33:"手续费低，收费清晰",
		p33: "不像币安每次都要去查手续费，他们的手续费画一在平仓时才收0.06%，节省了开支和确认的时间。",
        head43: "Zhu ki",
        head53: "中国人创立的交易所",
        p43: "意外的很不错，个人觉得比火币和okex都易用",
        head63: " 老王",
        btcc:"btcc",
        
    },
    Industry: {
        title: "热门行业新闻",
        industrynone:"industry-none",
    },
    Foot:{
		text:"风险提示：数字资产交易是一个新兴产业，存在光明前景，但正因为属于新事物，也蕴含巨大风险。特别是在杠杆交易中，杠杆倍数既同步扩大了盈利也同步放大了风险，请您务必对此行业、杠杆交易模式、交易规则等相关知识有充分了解后再参与。并且我们强烈建议您，在自身能够承担的风险范围内进行交易。交易有风险，入市须谨慎！",
		text2:"全球运营最久的交易所，稳健运营10周年 © 2011-2022 BTCC.com. All rights reserved"
	},
    bonus:{
        注册免费获取:"注册免费获取",
        模拟账户:"模拟账户",
        轻松训练提升交易技能:"轻松训练提升交易技能",
        注册获取模拟账户:"注册获取模拟账户",
        如何获取模拟账户:"如何获取模拟账户",
        第一步注册账户:"第一步：注册账户",
        第二步下载APP:"第二步：下载APP",
        第三步APP登录:"第三步：APP登录",
        第四步切换模拟账户:"第四步：切换模拟账户",
        第二步进入交易界面:"第二步：进入交易界面",
        第三步账号登录:"第三步：账号登录",
        新用户专享豪礼:"新用户专享豪礼",
        "14000USDT":"14,000USDT",
        注册后7天可领取:"注册后7天可领取",
        立即注册领取:"立即注册领取",
        如何领取新用户豪礼:"如何领取新用户豪礼",
        第二步下载官方APP:"第二步：下载官方APP",
        第三步用注册的账户登录:"第三步：用注册的账户登录",
        第四步APP内报名领取奖励:"第四步：APP内报名领取奖励",
        第二步进入官网:"第二步：进入官网",
        第三步用注册的账户登录:"第三步：用注册的账户登录",
        第四步官网内报名领取奖励:"第四步：官网内报名领取奖励",
    },
    lottery:{
        幸运转大奖:"幸运转大奖",
        每日送1BTCETH:"每日送1BTC & ETH",
        "10000USDTXRP":"10,000 USDT & XRP",
        活动规则:"活动规则：",
        注册账户后并下载手机APP参与活动期间每天从:"注册账户后并下载手机APP参与,活动期间每天从",
        "8点":"8点",
        开始宝箱倒计时结束即可抽奖:"开始，宝箱倒计时结束即可抽奖。 24小时内，幸运轮盘",
        抽奖仅3次:"抽奖仅3次",
        次日8点之前未抽奖的机会将失效 :"，次日8点之前未抽奖的机会将失效。 该活动的最高奖金为 ",
        "比特币(BTC)":"1 比特币 (BTC)！",
        "10000USDT模拟账户":"10,000USDT模拟账户",
        BTCC为您准备了高达:"BTCC为您准备了高达",
        "10000USDT":"10,000USDT",
        的模拟资金供您体验模拟交易:"的模拟资金供您体验模拟交易，这可以帮助您快速了解市场行情及交易规则，以便在真实交易中更快实现盈利。",
        "title": "用Visa/Master信用卡购买虚拟货币！",
        "li1": "BTCC成立于 2011 年，是全球运营时间最长的虚拟货币交易所",
    },
    coupon:{
        充就送10USDT:"充就送10USDT",
        "0.003BTC以上":"0.003BTC以上、0.04ETH以上、100XRP以上、100USDT以上",
        注册账户后在活动期间充值:"注册账户后,在活动期间充值",
        "100USDT以上":"100 USDT以上",
        即可获得10USDT赠金:"即可获得10USDT赠金。",
        活动规则:"活动规则：",
        "每个账户":"*每个账户",
        仅限领取1次:"仅限领取1次",
        领取后立即到账BTCC账户:" ，领取后立即到账BTCC账户，赠金可以抵扣用户在BTCC交易时产生的手续费用、过夜利息及交易亏损的金额补贴。",
    },
    rebate:{
        "交易返现":"交易返现",
        "500USDT":"500USDT",
        注册账户后:"注册账户后, 在活动期间进行合约交易, 所产生的手续费,将在交易次日24小时内返现15%,最多返现",
        "500USDT":"500USDT", 
    },
    countryList: {
        ISO_3166_716: '辛巴威',
        ISO_3166_074: '布威岛',
        ISO_3166_466: '马里',
        other: '其他',
        ISO_3166_072: '博茨瓦纳',
        ISO_3166_096: '汶莱',
        ISO_3166_100: '保加利亚',
        ISO_3166_854: '布其纳法索',
        ISO_3166_108: '布隆迪',
        ISO_3166_120: '喀麦隆',
        ISO_3166_124: '加拿大',
        ISO_3166_140: '中非共和国',
        ISO_3166_152: '智利',
        ISO_3166_162: '圣诞岛',
        ISO_3166_166: '可可斯群岛',
        ISO_3166_170: '哥伦比亚',
        ISO_3166_756: '瑞士',
        ISO_3166_178: '刚果',
        ISO_3166_184: '库克群岛',
        ISO_3166_188: '哥斯大黎加',
        ISO_3166_384: '象牙海岸',
        ISO_3166_192: '古巴',
        ISO_3166_196: '塞浦路斯',
        ISO_3166_203: '捷克共和国',
        ISO_3166_208: '丹麦',
        ISO_3166_262: '吉布提',
        ISO_3166_276: '德国',
        ISO_3166_012: '阿尔及利亚',
        ISO_3166_218: '厄瓜多尔',
        ISO_3166_818: '埃及',
        ISO_3166_732: '西撒哈拉',
        ISO_3166_724: '西班牙',
        ISO_3166_233: '爱沙尼亚',
        ISO_3166_246: '芬兰',
        ISO_3166_250: '法国',
        ISO_3166_583: '密克罗尼西亚',
        ISO_3166_266: '加蓬',
        ISO_3166_226: '赤道几内亚',
        ISO_3166_254: '法属圭亚那',
        ISO_3166_270: '冈比亚',
        ISO_3166_239: '格鲁吉亚',
        ISO_3166_288: '加纳',
        ISO_3166_292: '直布罗陀',
        ISO_3166_300: '希腊',
        ISO_3166_304: '格陵兰',
        ISO_3166_826: '英国',
        ISO_3166_308: '格林伍德',
        ISO_3166_312: '瓜德罗普',
        ISO_3166_316: '关岛',
        ISO_3166_320: '瓜地马拉',
        ISO_3166_624: '几内亚比绍',
        ISO_3166_328: '圭亚那',
        ISO_3166_191: '克罗地亚',
        ISO_3166_332: '海地',
        ISO_3166_340: '洪都拉斯',
        ISO_3166_348: '匈牙利',
        ISO_3166_352: '冰岛',
        ISO_3166_356: '印度',
        ISO_3166_360: '印度尼西亚',
        ISO_3166_364: '伊朗',
        ISO_3166_368: '伊拉克',
        ISO_3166_086: '英联邦的印度洋领域',
        ISO_3166_372: '爱尔兰',
        ISO_3166_376: '以色列',
        ISO_3166_380: '意大利',
        ISO_3166_388: '牙买加',
        ISO_3166_392: '日本',
        ISO_3166_400: '约旦',
        ISO_3166_398: '哈萨克斯坦',
        ISO_3166_214: '多明尼加',
        ISO_3166_404: '肯雅',
        ISO_3166_296: '基里巴斯',
        ISO_3166_408: '朝鲜',
        ISO_3166_410: '韩国',
        ISO_3166_116: '柬埔寨',
        ISO_3166_174: '科摩罗',
        ISO_3166_414: '科威特',
        ISO_3166_417: '吉尔吉斯斯坦',
        ISO_3166_136: '开曼群岛',
        ISO_3166_418: '老挝',
        ISO_3166_144: '斯里兰卡',
        ISO_3166_428: '拉托维亚',
        ISO_3166_422: '黎巴嫩',
        ISO_3166_426: '莱索托',
        ISO_3166_430: '利比理亚',
        ISO_3166_434: '利比亚',
        ISO_3166_438: '列支敦士登',
        ISO_3166_440: '立陶宛',
        ISO_3166_442: '卢森堡',
        ISO_3166_450: '马达加斯加',
        ISO_3166_454: '马拉维',
        ISO_3166_458: '马来西亚',
        ISO_3166_462: '马尔代夫',
        ISO_3166_470: '马尔他',
        ISO_3166_584: '马绍尔群岛',
        ISO_3166_478: '茅利塔尼亚',
        ISO_3166_480: '毛里求斯',
        ISO_3166_484: '墨西哥',
        ISO_3166_498: '莫尔达瓦',
        ISO_3166_492: '摩纳哥',
        ISO_3166_496: '蒙古',
        ISO_3166_504: '摩洛哥',
        ISO_3166_508: '莫三比克',
        ISO_3166_104: '缅甸',
        ISO_3166_516: '纳米比亚',
        ISO_3166_520: '瑙鲁',
        ISO_3166_524: '尼泊尔',
        ISO_3166_528: '荷兰',
        ISO_3166_540: '新赫里多尼亚',
        ISO_3166_554: '纽西兰',
        ISO_3166_558: '尼加拉瓜',
        ISO_3166_562: '尼日尔',
        ISO_3166_566: '尼日利亚',
        ISO_3166_570: '纽埃',
        ISO_3166_578: '挪威',
        ISO_3166_512: '阿曼',
        ISO_3166_586: '巴基斯坦',
        ISO_3166_260: '法属玻里尼西亚',
        ISO_3166_585: '帕劳',
        ISO_3166_591: '巴拿马',
        ISO_3166_598: '巴布亚新矶内亚',
        ISO_3166_600: '巴拉圭',
        ISO_3166_604: '秘鲁',
        ISO_3166_608: '菲律宾',
        ISO_3166_612: '皮特开恩群岛',
        ISO_3166_616: '波兰',
        ISO_3166_620: '葡萄牙',
        ISO_3166_630: '波多黎各（美）',
        ISO_3166_634: '卡塔尔',
        ISO_3166_642: '罗马尼亚',
        ISO_3166_643: '俄罗斯联邦',
        ISO_3166_646: '卢旺达',
        ISO_3166_222: '萨尔瓦多',
        ISO_3166_654: '圣赫勒那',
        ISO_3166_674: '圣马力诺',
        ISO_3166_678: '圣多美与普林西比',
        ISO_3166_682: '沙乌地阿拉伯',
        ISO_3166_686: '塞内加尔',
        ISO_3166_690: '塞舌耳',
        ISO_3166_694: '塞拉里昂',
        ISO_3166_702: '新加坡',
        ISO_3166_703: '斯洛伐克',
        ISO_3166_705: '斯洛文尼亚',
        ISO_3166_090: '所罗门群岛',
        ISO_3166_706: '索马里',
        ISO_3166_736: '苏丹',
        ISO_3166_740: '苏里南',
        ISO_3166_748: '斯威士兰',
        ISO_3166_752: '瑞典',
        ISO_3166_760: '叙利亚',
        ISO_3166_810: '苏联(前)',
        ISO_3166_148: '乍得',
        ISO_3166_762: '塔吉克斯坦',
        ISO_3166_834: '坦桑尼亚',
        ISO_3166_764: '泰国',
        ISO_3166_768: '多哥',
        ISO_3166_776: '汤加',
        ISO_3166_780: '特立尼达和多巴哥',
        ISO_3166_788: '突尼斯',
        ISO_3166_792: '土耳其',
        ISO_3166_626: '东帝',
        ISO_3166_795: '土库曼斯坦',
        ISO_3166_798: '图瓦卢',
        ISO_3166_800: '乌干达',
        ISO_3166_804: '乌克兰',
        ISO_3166_840: '美国',
        ISO_3166_858: '乌拉圭',
        ISO_3166_860: '乌兹别克斯坦',
        ISO_3166_336: '梵蒂冈（罗马教庭）',
        ISO_3166_862: '委内瑞拉',
        ISO_3166_704: '越南',
        ISO_3166_882: '西萨摩亚',
        ISO_3166_887: '叶门',
        ISO_3166_891: '南斯拉夫',
        ISO_3166_710: '南非',
        ISO_3166_180: '萨伊',
        ISO_3166_894: '尚比亚',
        ISO_3166_156: '中国',
        ISO_3166_158: '台湾',
        ISO_3166_446: '中国澳门',
        ISO_3166_344: '中国香港',
        ISO_3166_004: '阿富汗',
        ISO_3166_008: '阿尔巴尼亚',
        ISO_3166_020: '安道尔',
        ISO_3166_024: '安哥拉',
        ISO_3166_010: '南极洲',
        ISO_3166_028: '安堤瓜及巴尔布达',
        ISO_3166_032: '阿根廷',
        ISO_3166_051: '亚美尼亚',
        ISO_3166_533: '阿鲁巴',
        ISO_3166_036: '澳大利亚',
        ISO_3166_040: '奥地利',
        ISO_3166_031: '阿塞拜疆',
        ISO_3166_784: '阿联酋',
        ISO_3166_044: '巴哈马',
        ISO_3166_048: '巴林',
        ISO_3166_050: '孟加拉',
        ISO_3166_052: '巴巴多斯',
        ISO_3166_112: '白俄罗斯',
        ISO_3166_084: '伯利兹',
        ISO_3166_056: '比利时',
        ISO_3166_204: '贝宁',
        ISO_3166_060: '百慕大',
        ISO_3166_064: '不丹',
        ISO_3166_068: '玻利维亚',
        ISO_3166_070: '波黑',
        ISO_3166_076: '巴西',
        ISO_3166_772: '托克劳',
        ISO_3166_324: '几内亚',
        ISO_3166_231: '埃塞俄比亚',
        ISO_3166_242: '斐济',
        ISO_3166_238: '福克兰群岛',
      },
      "已有账户？":"已有账户？",
      "立即登录":"立即登录",
      "立即注册":"立即注册",
      "发送验证码成功":"发送验证码成功！如果没有收到验证码，请检查您的垃圾邮件文件夹。",
      search: "搜索",
    
}