export const en = {
  login: {
    "App Store 下载": "App Store",
    "Google Play 下载": "Google Play",
    注册: "Register Now",
    title: "Buy Crypto with <br/>Visa/Master Credit Card!",
    title2: "14000USDT",
    title3: "Exclusive for New Users",
    li1: "Founded in 2011, BTCC is the world's longest-running <br/> crypto exchange",
    li1_1: "Founded in 2011, BTCC is the world's longest-running crypto exchange",
    li2: "US MSB and CA MSB licensed",
    li3: "18+ cryptocurrencies available ",
    li4: "Allows for deposits in 9+ fiat currencies",
    li5: "Trade with 1 - 150 leverage ",
    li6: "Meet the deposit targets within 7 days after successful registration at BTCC, and you can enjoy the bonus of the corresponding target levels.",
    tab1: "Email ",
    email: "Please enter your email address",
    code: "Please enter the verification code",
    Send: "Get code",
    pwd: "Password must be 6–16 characters containing both numbers and letters.",
    tab2: "Mobile",
    mobile: "Please enter your mobile number",
    Register: "Register Now",
    服务条款: "https://www.btcc.com/en-US/detail/142641.html",
    隐私声明: "https://www.btcc.com/en-US/detail/142638.html",
    风险提示: "https://www.btcc.com/en-US/detail/149036.html",
    and: "and",
    "首页url":"https://lp.btcc.academy/en-GB",
    "首页":"Home",
    "关于我们":"About Us",
    "about":"en-GB/about-us.html",
    "about_in":"about_in-us.html",
    "about_dm":"about_dm-us.html",
    "about_dms":"download/about_dm-kr.html",
    "about_coupon":"about_coupon-us.html",
    "about_rebate":"about_rebate-us.html",
    "about_lottery":"about_lottery-us.html",

    mobileTip: "Please enter a valid mobile number",
    emailTip: "Please enter a valid email address",
    codeTip: "Please enter a valid verification code",
   pwdTip:
      "Password must be at least 8 characters with both uppercase and lowercase letters and numbers/special characters.",
    tips: "I have read and agree to BTCC's",
    tips1: "《User agreement》",
    tips2: "《Privacy policy》",
    tips3: "《Risk Disclosure》",

    registeSuccessInfo: "Thank you for your registration. ",
    startTrading: "Start trading now!",
    registeSuccessInfoPhone: "Thank you for your registration. ",
    startTradingPhone: "Start trading now!",
    注册成功:"Registration successful",
    下载应用:"Download the app to get your <br/><span>10 USDT</span> bonus!",
    立即下载:"Download now"
  },
  download:{
    取得業界權威:"Obtained industry authoritative supervision MSB certification",
    更低:"<span>Lower</span> transaction costs<br class='phone-br'/> <span>Higher</span> transaction profits",
    靈活的高槓桿:"Flexible high<br class='phone-br'/> leverage（<span>1X-225X</span>）" ,
    行業最低:"Industry lowest rates",
    模擬交易金體驗:"$100,000 simulated <br class='phone-br'/>amount trading experience",
    小時交易:"200+ products,<br class='phone-br'/>24/7 trading",
    完成交易任務:"Complete trading tasks<br class='phone-br'/>and receive up to $10,055",
    立即下载领取:"<span>Download now and get $10</span> Up to $10,055",
    appstore:"/download/appstore-en.png",
    googleplay:"/download/googleplay-en.png",
    img:"/download/img.png",
    風險提示:"Risk warning: Digital asset trading is not suitable for use by anyone in countries and regions subject to certain jurisdictions. Digital asset trading is an emerging industry with bright prospects, but because it is a new thing, it also contains huge risks. Leverage multipliers simultaneously amplify profits and risks. Please be sure to have a full understanding of the industry, leverage trading models, trading rules and other relevant knowledge before participating. And we strongly recommend that you trade within the risk range you can bear."
  },
  countryList: {
    ISO_3166_704: "Vietnam",
    ISO_3166_156: "China",
    ISO_3166_344: "Hong Kong (China)",
    ISO_3166_158: "Taiwan",
    ISO_3166_446: "Macau",
    ISO_3166_004: "Afghanistan",
    ISO_3166_008: "Albania",
    ISO_3166_020: "Andorra",
    ISO_3166_024: "Angola",
    ISO_3166_010: "Antarctica",
    ISO_3166_028: "Antigua and Barbuda",
    ISO_3166_032: "Argentina",
    ISO_3166_051: "Armenia",
    ISO_3166_533: "Aruba",
    ISO_3166_036: "Australia",
    ISO_3166_040: "Austria",
    ISO_3166_031: "Azerbaijan",
    ISO_3166_784: "United Arab Emirates",
    ISO_3166_044: "Bahamas",
    ISO_3166_048: "Bahrain",
    ISO_3166_050: "Bangladesh",
    ISO_3166_052: "Barbados",
    ISO_3166_112: "Belarus",
    ISO_3166_084: "Belize",
    ISO_3166_056: "Belgium",
    ISO_3166_204: "Benin",
    ISO_3166_060: "Bermuda Islands",
    ISO_3166_064: "Bhutan",
    ISO_3166_068: "Bolivia",
    ISO_3166_070: "Bosnia and Herzegovina",
    ISO_3166_072: "Botswana",
    ISO_3166_076: "Brazil",
    ISO_3166_096: "Brunei",
    ISO_3166_100: "Bulgaria",
    ISO_3166_854: "Burkina Faso",
    ISO_3166_108: "Burundi",
    ISO_3166_120: "Cameroon",
    ISO_3166_124: "Canada",
    ISO_3166_140: "Central African Republic",
    ISO_3166_152: "Chile",
    ISO_3166_162: "Christmas Island",
    ISO_3166_166: "Coco Islands",
    ISO_3166_170: "Colombia",
    ISO_3166_756: "Switzerland",
    ISO_3166_178: "Congo",
    ISO_3166_184: "Cook Island",
    ISO_3166_188: "Costa Rica",
    ISO_3166_384: "Ivory Coast",
    ISO_3166_192: "Cuba",
    ISO_3166_196: "Cyprus",
    ISO_3166_203: "Czech Republic",
    ISO_3166_208: "Denmark",
    ISO_3166_262: "Djibouti",
    ISO_3166_276: "Germany",
    ISO_3166_214: "Dominican Republic",
    ISO_3166_012: "Algeria",
    ISO_3166_218: "Ecuador",
    ISO_3166_818: "Egypt",
    ISO_3166_732: "Western Sahara",
    ISO_3166_724: "Spain",
    ISO_3166_233: "Estonia",

    ISO_3166_231: "Ethiopia",
    ISO_3166_242: "Fiji",
    ISO_3166_238: "Falkland Islands",
    ISO_3166_246: "Finland",
    ISO_3166_250: "France",
    ISO_3166_583: "Micronesia",
    ISO_3166_266: "Gabon",
    ISO_3166_226: "Equatorial Guinea",
    ISO_3166_254: "French Guyana",
    ISO_3166_270: "Gambia",
    ISO_3166_239: "Georgia",
    ISO_3166_288: "Ghana",
    ISO_3166_292: "Gibraltar",
    ISO_3166_300: "Greece",
    ISO_3166_304: "Greenland",
    ISO_3166_826: "United Kingdom",
    ISO_3166_308: "Grenada",
    ISO_3166_312: "Guadeloupe",
    ISO_3166_316: "Guam",
    ISO_3166_320: "Guatemala",
    ISO_3166_324: "Guinea",
    ISO_3166_624: "Guinea Bissau",
    ISO_3166_328: "Guyana",
    ISO_3166_191: "Croatia",
    ISO_3166_332: "Haiti",
    ISO_3166_340: "Honduras",
    ISO_3166_348: "Hungary",
    ISO_3166_352: "Iceland",
    ISO_3166_356: "India",
    ISO_3166_360: "Indonesia",
    ISO_3166_364: "Iran",
    ISO_3166_368: "Iraq",
    ISO_3166_086: "British Indian Ocean Territory",
    ISO_3166_372: "Ireland",
    ISO_3166_376: "Israel",
    ISO_3166_380: "Italy",
    ISO_3166_388: "Jamaica",
    ISO_3166_392: "Japan",
    ISO_3166_400: "Jordan",
    ISO_3166_398: "Kazakhstan",
    ISO_3166_404: "Kenya",
    ISO_3166_296: "Kiribas",
    ISO_3166_408: "North Korea",
    ISO_3166_410: "Korea",
    ISO_3166_116: "Cambodia",
    ISO_3166_174: "Comoros",
    ISO_3166_414: "Kuwait",
    ISO_3166_417: "Kyrgyzstan",
    ISO_3166_136: "Cayman Islands",
    ISO_3166_418: "In stock",
    ISO_3166_144: "Sri Lanka",
    ISO_3166_428: "Latvia",
    ISO_3166_422: "Lebanon",
    ISO_3166_426: "Lesotho",
    ISO_3166_430: "Liberia",
    ISO_3166_434: "Libya",
    ISO_3166_438: "Liechtenstein",
    ISO_3166_440: "Lithuania",
    ISO_3166_442: "Luxembourg",
    ISO_3166_450: "Madagascar",
    ISO_3166_454: "Malawi",
    ISO_3166_458: "Malaysia",
    ISO_3166_462: "Maldives",
    ISO_3166_466: "Mali",
    ISO_3166_470: "Malta",
    ISO_3166_584: "Marshall Islands",
    ISO_3166_478: "Mauritania",
    ISO_3166_480: "Mauritius",
    ISO_3166_484: "Mexico",
    ISO_3166_498: "Moldova",
    ISO_3166_492: "Monaco",
    ISO_3166_496: "Mongolia",
    ISO_3166_504: "Morocco",
    ISO_3166_508: "Mozambique",
    ISO_3166_104: "Myanmar",
    ISO_3166_516: "Namibia",
    ISO_3166_520: "Nauru",
    ISO_3166_524: "Nepal",
    ISO_3166_528: "Netherlands",
    ISO_3166_540: "New Caledonia",
    ISO_3166_554: "New Zealand",
    ISO_3166_558: "Nicaragua",
    ISO_3166_562: "Niger",
    ISO_3166_566: "Nigeria",
    ISO_3166_570: "Niue Island",
    ISO_3166_578: "Norway",
    ISO_3166_512: "Oman",
    ISO_3166_586: "Pakistan",
    ISO_3166_260: "French Polynesia",
    ISO_3166_585: "Palau",
    ISO_3166_591: "Panama",
    ISO_3166_598: "Papua New Guinea",
    ISO_3166_600: "Paraguay",
    ISO_3166_604: "Peru",
    ISO_3166_608: "Philippines",
    ISO_3166_612: "Pitcairn Islands",
    ISO_3166_616: "Poland",
    ISO_3166_620: "Portugal",
    ISO_3166_630: "Puerto Rico",
    ISO_3166_634: "Qatar",
    ISO_3166_642: "Romania",
    ISO_3166_643: "Russia",
    ISO_3166_646: "Rwanda",
    ISO_3166_222: "El Salvador",
    ISO_3166_654: "St. Helena",
    ISO_3166_674: "San Marino",
    ISO_3166_678: "Sao Tome and Principe",
    ISO_3166_682: "Saudi Arabia",
    ISO_3166_686: "Senegal",
    ISO_3166_690: "Seychelles",
    ISO_3166_694: "Sierra Leone",
    ISO_3166_702: "Singapore",
    ISO_3166_703: "Slovakia",
    ISO_3166_705: "Slovenia",
    ISO_3166_090: "Solomon Islands",
    ISO_3166_706: "Somalia",
    ISO_3166_736: "Sudan",
    ISO_3166_740: "Suriname",
    ISO_3166_748: "Swaziland",
    ISO_3166_752: "Sweden",
    ISO_3166_760: "Syria",
    ISO_3166_810: "Soviet Union",
    ISO_3166_148: "Chad",
    ISO_3166_762: "Tajikistan",
    ISO_3166_834: "Tanzania",
    ISO_3166_764: "Thailand",
    ISO_3166_768: "Togo",
    ISO_3166_772: "Tokla",
    ISO_3166_776: "Tonga",
    ISO_3166_780: "Trinidad and Tobago",
    ISO_3166_788: "Tunisia",
    ISO_3166_792: "Turkey",
    ISO_3166_626: "East Timor",
    ISO_3166_795: "Turkmenistan",
    ISO_3166_798: "Tuvalu",
    ISO_3166_800: "Uganda",
    ISO_3166_804: "Ukraine",
    ISO_3166_840: "United States",
    ISO_3166_858: "Uruguay",
    ISO_3166_860: "Uzbekistan",
    ISO_3166_336: "Vatican",
    ISO_3166_862: "Venezuela",
    ISO_3166_882: "Western Samoa",
    ISO_3166_887: "Yemen",
    ISO_3166_891: "Yugoslavia",
    ISO_3166_710: "South Africa",
    ISO_3166_180: "Zaire",
    ISO_3166_894: "Zambia",
    ISO_3166_716: "Zimbabwe",
    other: "Other",
    ISO_3166_074: "Boway Island",
  },
  "已有账户？": "Already have an account？",
  立即登录: "Login now.",
  立即注册: "Register Now",
  发送验证码成功:
    "Verification code sent successfully！In case a verification code doesn't arrive, check your Spam/Junk folder. ",
  search: "Search",
};
