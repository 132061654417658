import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Index_tw = lazy(() => import('./pages/zhTW.js'))
const Invests_download = lazy(() => import('./components/RegisterThirdPartyInvest/download'))
// const Deposit = lazy(() => import('./pages/deposit'))
export default () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/fb/ko-KR:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Index}/>
        <Route exact path="/fb/zh-TW:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Index_tw}/>
        <Route exact path="/gg/ko-KR:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Index}/>
        <Route exact path="/gg/zh-TW:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Index_tw}/>
        {/* <Route  path="/deposit" component={Deposit} /> */}
        <Route  path="/invests_download/:lang(en-US|ja-JP|zh-TW|ko-KR)?/" component={Invests_download} />
      </Switch>
    </BrowserRouter>
  );
};
